
/**
 * ALL THE FUNCTIONS HERE ARE DEPRECATED
 */

/**
 * Define some videos as well as its names, so we can call them on every
 * part of the code, when the video_tag helper is defined
 * 
 */
var videos = {
  'depoimento1':          'https://www.youtube.com/embed/pUUfxjEa-qY',
  'depoimento2':          'https://www.youtube.com/embed/OuAGEFBEw4Y',
  'depoimento3':          'https://www.youtube.com/embed/B9FLLEap4c8',
  'depoimento4':          'https://www.youtube.com/embed/Gmo8yVP_TaM',
  'depoimento5':          'https://www.youtube.com/embed/xgErBJe2M70',
  'depoimento6':          'https://www.youtube.com/embed/A35f6EDzKt4',
  'depoimento7':          'https://www.youtube.com/embed/tUnT_CnLqig',
  'depoimento8':          'https://www.youtube.com/embed/HyKS8sao7bw',
  'depoimento9':          'https://www.youtube.com/embed/2P1A2HAelMo',
  'captadores_video':     'https://www.youtube.com/embed/XHOmBV4js_E',
  'sete_bemvindo_video':  'https://www.youtube.com/embed/dhlYT6YM-1Y',
  'sete_video':           'https://www.youtube.com/embed/hihsNinwPOs'
  }

/** Dynamically inject the iframe on demand of the user.
 *  Pull the youtube url from the data attribute on the wrapper element.
 */
function insert_video(video_id) {
  video_url = get_video_by_id(video_id)
  console.log("video_url: " + video_url)
  $('#' + video_id).html(
    '<iframe class="video" src="' + video_url + '?autoplay=1"' + 
    'title="Video Sete Capital"' + 
    'allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;" ' +
    'allowfullscreen listType=playlist' + 
    '></iframe>');
}

function get_video_by_id(video_id) {
  for (let key in videos) {
    if (video_id.includes(key)) {
      return videos[key]
    }
  }

}

// this resets the video frame in case it is needed.
function resetVideo(video_id) {
  var video = $("#playerid").attr("src");
  $("#playerid").attr("src","");
  $("#playerid").attr("src",video);
}

jQuery(window).on('load', function() {
  $('.play-youtube-video').on('click', function() {
    insert_video($(this).parent().attr('id'));
  })
  
  $('.play').on('click', function() {
    insert_video($(this).parent().attr('id'));
  })
})