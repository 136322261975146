//primeira
// $(document).ready(function() {
//     function isOutOfViewport(element) {
//         var rect = element.getBoundingClientRect();
//         return rect.bottom < 0 || rect.right < 0 || rect.left > window.innerWidth || rect.top > window.innerHeight;
//     }

//     function moveForm() {
//         var windowWidth = $(window).width();
//         $(".formtop").appendTo(windowWidth <= 959 ? ".formmobile" : ".formtop");
//     }

//     function checkDivPosition() {
//         var mainForm = $('#main-form');
//         var scrollTop = window.pageYOffset;
//         var lastScrollTop = $(window).data('lastScrollTop');
//         var formBottomOffset = $(".formbottom").offset().top;

//         if (isOutOfViewport(mainForm[0])) {
//             if (scrollTop > lastScrollTop && scrollTop < formBottomOffset) {
//                 mainForm.appendTo(".formbottom");
//             } else if (scrollTop < lastScrollTop && scrollTop < formBottomOffset) {
//                 mainForm.appendTo(".formtop");
//             }
//         }
//         $(window).data('lastScrollTop', scrollTop);
//     }  

//     moveForm();
//     $(window).on("resize", moveForm);
//     $(window).on("scroll", checkDivPosition);   
// });


//segunda
// function isOutOfViewport(element) {
//     var rect = element.getBoundingClientRect();
//     return rect.bottom < 0 || rect.right < 0 || rect.left > window.innerWidth || rect.top > window.innerHeight;
// }

// function moveForm() {
//     var windowWidth = $(window).width();
//     $(".formtop").appendTo(windowWidth <= 959 ? ".formmobile" : ".formtop");
// }

// function checkDivPosition() {
//     var mainForm = $('#main-form');
//     var scrollTop = window.pageYOffset;
//     var lastScrollTop = $(window).data('lastScrollTop');
//     var formBottomOffset = $(".formbottom").offset().top;

//     if (isOutOfViewport(mainForm[0])) {
//         if (scrollTop > lastScrollTop && scrollTop < formBottomOffset) {
//             mainForm.appendTo(".formbottom");
//         } else if (scrollTop < lastScrollTop && scrollTop < formBottomOffset) {
//             mainForm.appendTo(".formtop");
//         }
//     }
//     $(window).data('lastScrollTop', scrollTop);
// }  

// function checkDivPosition1() {
//     var mainForm = $('#main-form1');
//     var scrollTop = window.pageYOffset;
//     var lastScrollTop = $(window).data('lastScrollTop');
//     var formBottomOffset = $(".formbottommobile").offset().top;

//     if (isOutOfViewport(mainForm[0])) {
//         if (scrollTop > lastScrollTop && scrollTop < formBottomOffset) {
//             mainForm.appendTo(".formbottommobile");
//         } else if (scrollTop < lastScrollTop && scrollTop < formBottomOffset) {
//             mainForm.appendTo(".formmobile");
//         }
//     }
//     $(window).data('lastScrollTop', scrollTop);
// } 

// $(document).ready(function() {
//     moveForm();
//     $(window).on("resize", moveForm);
//     $(window).on("scroll", function() {
//         checkDivPosition();
//         checkDivPosition1();
//     });
// });


//terceira
// function isOutOfViewport(element) {
//     var rect = element.getBoundingClientRect();
//     return rect.bottom < 0 || rect.right < 0 || rect.left > window.innerWidth || rect.top > window.innerHeight;
// }

// function checkDivPosition() {
//     var mainForm = $('#main-form');
//     var scrollTop = window.pageYOffset;
//     var lastScrollTop = $(window).data('lastScrollTop');
//     var formBottomOffset = $(".formbottom").offset().top;

//     if (isOutOfViewport(mainForm[0])) {
//         if (scrollTop > lastScrollTop && scrollTop < formBottomOffset) {
//             mainForm.appendTo(".formbottom");
//         } else if (scrollTop < lastScrollTop && scrollTop < formBottomOffset) {
//             mainForm.appendTo(".formtop");
//         }
//     }
//     $(window).data('lastScrollTop', scrollTop);
// }  

// $(document).ready(function() {
//     $(window).on("scroll", checkDivPosition);
// });

//quarta

function isOutOfViewport(element) {
    var rect = element.getBoundingClientRect();
    return rect.bottom < 0 || rect.right < 0 || rect.left > window.innerWidth || rect.top > window.innerHeight;
}

function checkDivPosition() {
    var mainForm;
    var formBottomOffset;
    
    if (window.innerWidth <= 959) {
        mainForm = $('#main-form1');
        formBottomOffset = $(".formbottommobile").offset().top;
    } else {
        mainForm = $('#main-form');
        formBottomOffset = $(".formbottom").offset().top;
    }
    
    var scrollTop = window.pageYOffset;
    var lastScrollTop = $(window).data('lastScrollTop');

    if (isOutOfViewport(mainForm[0])) {
        if (scrollTop > lastScrollTop && scrollTop < formBottomOffset) {
            if (window.innerWidth <= 959) {
                mainForm.appendTo(".formbottommobile");
            } else {
                mainForm.appendTo(".formbottom");
            }
        } else if (scrollTop < lastScrollTop && scrollTop < formBottomOffset) {
            if (window.innerWidth <= 959) {
                mainForm.appendTo(".formmobile");
            } else {
                mainForm.appendTo(".formtop");
            }
        }
    }
    $(window).data('lastScrollTop', scrollTop);
}

$(document).ready(function() {
    $(window).on("scroll", checkDivPosition);
});

