/**
 * Similar to Validations, masking is just a front-end feature to help the UX, masking 
 * the fields to match a beauty pattern :)
 */

/**
 * Remove the special chars from a string and return this string cleaned
 * @param {string} str 
 * @returns string
 */
function removeSpecialChars(str) {
  return str.replace(/[^a-zA-Z0-9 ]/g, '');
}

/**
 * Mask the phone input with the default masking for brazilian numbers.
 * TODO: implement this to receive the target phone input as a param,
 * so we can work on specific inputs in case we have multiple forms on the page
 */
function maskPhoneInput(target){
  var number =target.val()
  number = number.replace(/\D/g, '')
  target.val(maskPhone(number))
}


/**
 * Mask a string with de default brazilian mask for phone numbers 
 * @param {string} number
 * @return {string} new_number
 */
function maskPhone(number){
  number = number.replace(/\D/g, '')
  new_number = ""
  for(i =0; i< number.length; i++){
    if(i==0 && number[i] != '0') new_number += "(" + number[i]
    if(i==1) new_number += number[i]
    if(i==2 && number[i] != '0' && number[i] != '1' ) new_number += ") " + number[i]
    if(i==3) new_number += number[i]
    if(i==4) new_number += number[i]
    if(i==5) new_number += number[i]
    if(number.length < 11){
        if(i==6){
            new_number += "-" + number[i]
        }
        if(i==7){
            new_number += number[i]
        }
    }else{
        if(i==6){
        new_number += number[i]
        }
        if(i==7){
            new_number +=  "-" + number[i]
        }
    }
    if(i==8){
        new_number += number[i]
    }
    if(i==9){
        new_number += number[i]
    }
    if(i==10){
        new_number += number[i]
    }
  }
  return new_number
}

jQuery(window).on('load', function() {

  // focus and keyup listener for phone_input
  $(".phone_inputex").on("focus", function(){
    $(this).on("keyup", function(){
      maskPhoneInput($(this))
    })
  })
  
  // load listener for the phone elements on the page
  $(".phone").ready(function(){
    for(var i = 0; i < $(".phone").length; i++){
      number = $(".phone")[i].innerHTML
      $(".phone")[i].innerHTML = maskPhone(number)
    }
  })

  // keyup listener for no special chars elements on the page
  $(".no_special_chars").on("keyup", function(){
    $(this).val(removeSpecialChars($(this).val()))
  })

  $(".cpf_input").on("focus", function(){
    $(this).on("keyup", function(){
        maskCPFInput()
    } )
})

$(".cpf").ready(function(){
  for(var i = 0; i < $(".cpf").length; i++){
    content = $(".cpf")[i].innerHTML
    $(".cpf")[i].innerHTML = maskCPF(content)
  }
})

$(".cnpj_input").on("focus", function(){
  $(this).on("keyup", function(){
      maskCNPJInput()
  } )
})

$(".cnpj").ready(function(){
  for(var i = 0; i < $(".cnpj").length; i++){
    content = $(".cnpj")[i].innerHTML
    $(".cnpj")[i].innerHTML = maskCNPJ(content)
  }
})

$(".no_special_chars").on("keyup", function(){
  $(this).val(removeSpecialChars($(this).val()))
})

function removeSpecialChars(str) {
  return str.replace(/[^a-zA-Z0-9 ]/g, '');
}

function maskCPFInput(){
  var content = $(".cpf_input").val()
  content = content.replace(/\D/g, '')
  if(content.length == "11"){
    $(".cpf_input").val(maskCPF(content))
  } else {
    return false
  }
}

function maskCNPJInput(){
  var content = $(".cnpj_input").val()
  content = content.replace(/\D/g, '')
  if(content.length == "14"){
    $(".cnpj_input").val(maskCNPJ(content))
  } else {
    return false
  } 
}

function maskCPF(content){
  content = content.replace(/\D/g, '')
  new_content = ""
  for(i =0; i< content.length; i++) {
    if(i==0) new_content += content[i]
    if(i==1) new_content += content[i]
    if(i==2) new_content += content[i]
    if(i==3) new_content += "." + content[i]
    if(i==4) new_content += content[i]
    if(i==5) new_content += content[i]
    if(i==6) new_content += "." + content[i]
    if(i==7) new_content += content[i]
    if(i==8) new_content += content[i]
    if(i==9) new_content += "-" + content[i]
    if(i==10) new_content += content[i]
  }                 
  return new_content
}

function maskCNPJ(content){
  content = content.replace(/\D/g, '')
  new_content = ""
  for(i =0; i< content.length; i++) {
    if(i==0) new_content += content[i]
    if(i==1) new_content += content[i]
    if(i==2) new_content += "." + content[i]
    if(i==3) new_content += content[i]
    if(i==4) new_content += content[i]
    if(i==5) new_content += "." + content[i]
    if(i==6) new_content += content[i]
    if(i==7) new_content += content[i]
    if(i==8) new_content += "/" + content[i]
    if(i==9) new_content += content[i]
    if(i==10) new_content += content[i]
    if(i==11) new_content += content[i]
    if(i==12) new_content += "-" + content[i]
    if(i==13) new_content += content[i]
  }
  return new_content
}

})