import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["panel"]

  connect() {
    console.log("Accordion ON")
    this.panelTargets.forEach((panel, index) => {
      const button = this.element.querySelectorAll(".accordion")[index]
      button.addEventListener("click", () => this.togglePanel(panel))
    })
  }

  togglePanel(panel) {
    const isActive = panel.style.maxHeight
    this.panelTargets.forEach(p => p.style.maxHeight = null)
    if (!isActive) {
      panel.style.maxHeight = panel.scrollHeight + "px"
    }
  }
}
