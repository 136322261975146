import { Controller } from "stimulus"
// export default class extends Controller {
//   static targets = ["container"];

//   initialize() {
//     this.widthScreen();
//     window.addEventListener("resize", this.widthScreen.bind(this));

//     this.slideIndex = 1;
//     const slides = this.containerTarget.getElementsByClassName("mySlides");
//     const dotsContainer = this.element.querySelector(".dots");

//     if (slides.length > 0) {
//       this.showSlides(this.slideIndex);

//       const numSlides = slides.length;
//       const numGroups = Math.floor(numSlides / this.numberSlides()); // Chamar a função numberSlides()

//       for (let i = 0; i < numGroups; i++) {
//         const dot = document.createElement("span");
//         dot.classList.add("dot");
//         dot.dataset.carouselIndex = i * this.numberSlides() + 1; // Chamar a função numberSlides()
//         if (dotsContainer !== null) {
//           dotsContainer.appendChild(dot);
//         }
//       }

//       if (numSlides % this.numberSlides() !== 0) {
//         const remainingSlides = numSlides % this.numberSlides();
//         const lastDot = document.createElement("span");
//         lastDot.classList.add("dot");
//         lastDot.dataset.carouselIndex = numSlides - remainingSlides + 1;
//         if (dotsContainer !== null) {
//           dotsContainer.appendChild(lastDot);
//         }
//       }
//     }
//   }

//   widthScreen() {
//     const screenWidth = window.innerWidth;
//     console.log("Screen width:", screenWidth);

//     this.numberSlides();
//   }

//   numberSlides() {
//     const screenWidth = window.innerWidth;
//     if (screenWidth <= 589) {
//       return 1;
//     } else if (screenWidth >= 590 && screenWidth < 880) {
//       return 2;
//     } else {
//       return 3;
//     }
//   }

//   previous() {
//     const slides = this.containerTarget.getElementsByClassName("mySlides");
//     if (slides.length === 0 || this.slideIndex === 1) {
//       return;
//     }
//     this.showSlides(this.slideIndex - 1);
//   }

//   next() {
//     const slides = this.containerTarget.getElementsByClassName("mySlides");
//     const numSlides = slides.length;
//     const numDots = Math.ceil(numSlides / this.numberSlides());

//     if (numDots < this.slideIndex + 1) {
//       return;
//     }

//     this.showSlides(this.slideIndex + 1);
//   }

//   goToSlide(event) {
//     const index = parseInt(event.target.dataset.carouselIndex);
//     if (!isNaN(index)) {
//       this.showSlides(index);
//     }
//   }

//   showSlides(n) {
//     const slides = this.containerTarget.getElementsByClassName("mySlides");
//     const dots = this.element.getElementsByClassName("dot");

//     const numSlides = slides.length;
//     const numDots = Math.ceil(numSlides / this.numberSlides());

//     // Hide all slides and dots
//     for (let i = 0; i < numSlides; i++) {
//       if (slides[i] !== undefined) {
//         slides[i].style.display = "none";
//       }
//     }
//     for (let i = 0; i < dots.length; i++) {
//       if (dots[i] !== undefined) {
//         dots[i].style.display = "none";
//       }
//     }
//     for (let i = 0; i < dots.length; i++) {
//       if (dots[i] !== undefined) {
//         dots[i].classList.remove("active");
//       }
//     }

//     // Show the slides for the selected group
//     const start = (n - 1) * this.numberSlides();
//     const end = Math.min(start + (this.numberSlides() - 1), numSlides - 1);
//     for (let i = start; i <= end; i++) {
//       if (slides[i] !== undefined) {
//         slides[i].style.display = "block";
//       }
//     }

//     // Show dots for every group of slides
//     for (let i = 0; i < numDots; i++) {
//       if (dots[i] !== undefined) {
//         dots[i].style.display = "block";
//       }
//     }

//     // Mark the active dot
//     for (let i = 0; i < dots.length; i++) {
//       if (dots[i] !== undefined && dots[i].dataset.carouselIndex == n) {
//         dots[i].classList.add("active");
//         break;
//       }
//     }

//     this.slideIndex = n;
//     console.log(n);
//   }
// }

export default class extends Controller {
  static targets = ["container"];
  slideIndex = 1;

  initialize() {
    this.widthScreen();
    window.addEventListener("resize", this.widthScreen.bind(this));

    const slides = this.containerTarget.getElementsByClassName("mySlides");
    const dotsContainer = this.element.querySelector(".dots");

    if (slides.length > 0) {
      this.showSlides(this.slideIndex);

      const numSlides = slides.length;
      const numGroups = Math.floor(numSlides / this.numberSlides());

      for (let i = 0; i < numGroups; i++) {
        this.createDot(dotsContainer, i * this.numberSlides() + 1);
      }

      if (numSlides % this.numberSlides() !== 0) {
        const remainingSlides = numSlides % this.numberSlides();
        this.createDot(dotsContainer, numSlides - remainingSlides + 1);
      }
    }
  }

  widthScreen() {
    const screenWidth = window.innerWidth;
    console.log("Screen width:", screenWidth);

    this.numberSlides();
  }

  numberSlides() {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 589) {
      return 1;
    } else if (screenWidth < 880) {
      return 2;
    } else if (screenWidth < 1024) {
      return 3;
    } else {
      return 4;
    }
  }

  createDot(dotsContainer, carouselIndex) {
    const dot = document.createElement("span");
    dot.classList.add("dot");
    dot.dataset.carouselIndex = carouselIndex;
    if (dotsContainer !== null) {
      dotsContainer.appendChild(dot);
    }
  }

  previous() {
    const slides = this.containerTarget.getElementsByClassName("mySlides");
    if (slides.length === 0 || this.slideIndex === 1) {
      return;
    }
    this.showSlides(this.slideIndex - 1);
  }

  next() {
    const slides = this.containerTarget.getElementsByClassName("mySlides");
    const numSlides = slides.length;
    const numDots = Math.ceil(numSlides / this.numberSlides());

    if (numDots < this.slideIndex + 1) {
      return;
    }

    this.showSlides(this.slideIndex + 1);
  }

  goToSlide(event) {
    const index = parseInt(event.target.dataset.carouselIndex);
    if (!isNaN(index)) {
      this.showSlides(index);
    }
  }

  showSlides(n) {
    const slides = this.containerTarget.getElementsByClassName("mySlides");
    const dots = this.element.getElementsByClassName("dot");

    const numSlides = slides.length;
    const numDots = Math.ceil(numSlides / this.numberSlides());

    this.hideElements(slides);
    this.hideElements(dots);
    this.removeActiveClass(dots);

    const start = (n - 1) * this.numberSlides();
    const end = Math.min(start + (this.numberSlides() - 1), numSlides - 1);
    this.showElements(slides, start, end);
    this.showElements(dots, 0, numDots - 1);
    this.markActiveDot(dots, n);

    this.slideIndex = n;
    console.log(n); 
  }

  hideElements(elements) {
    Array.from(elements).forEach((element) => {
      if (element !== undefined) {
        element.style.display = "none";
      }
    });
  }

  showElements(elements, start, end) {
    for (let i = start; i <= end; i++) {
      if (elements[i] !== undefined) {
        elements[i].style.display = "block";
      }
    }
  }

  removeActiveClass(elements) {
    Array.from(elements).forEach((element) => {
      if (element !== undefined) {
        element.classList.remove("active");
      }
    });
  }

  markActiveDot(dots, n) {
    for (let i = 0; i < dots.length; i++) {
      if (dots[i] !== undefined && dots[i].dataset.carouselIndex == n) {
        dots[i].classList.add("active");
        break;
      }
    }
  }
}





