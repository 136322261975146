/**
 * Disables the submit button(s) for a specific form
 * @param {jQuery} $form jQuery object representing the form
 */
function disableSubmitButton($form){
  $form.find(".btn-submit").each(function() {
    $(this).prop("disabled", true).addClass("disabled");
  });
}

/**
* Enables the submit button(s) for a specific form
* @param {jQuery} $form jQuery object representing the form
*/
function enableSubmitButton($form){
  $form.find(".btn-submit").each(function() {
    $(this).prop("disabled", false).removeClass("disabled");
  });
}

/**
* Defines the submit button state for a specific form based on the state value
* @param  {jQuery} $form jQuery object representing the form
* @param  {boolean} state
*/
function setSubmitButtonState($form, state){
  if(state){
      enableSubmitButton($form);
  } else {
      disableSubmitButton($form);
  }
}

jQuery(window).on('load', function(){

  // KeyUp listener do phone_input
  $(".phone_input").on("keyup", function(){
      const $form = $(this).closest(".validate");
      const phoneValid = $(this).val().length >= 14;
      if(phoneValid){
          $(this).parent().addClass("valid").removeClass("invalid");
      } else {
          $(this).parent().addClass("invalid").removeClass("valid");
      }
      setSubmitButtonState($form, isFormValid($form));
  });

  // Focus e Keyup listener do name_input
  $(".name_input").on("focus keyup", function(){
      const $form = $(this).closest(".validate");
      const nameValid = $(this).val().length > 2;
      if(nameValid){
          $(this).parent().addClass("valid").removeClass("invalid");
      } else {
          $(this).parent().addClass("invalid").removeClass("valid");
      }
      setSubmitButtonState($form, isFormValid($form));
  });

  // Função para verificar se o formulário é válido
  function isFormValid($form) {
    const phoneValid = $form.find(".phone_input").val().length >= 14;
    const nameValid = $form.find(".name_input").val().length > 2;
    return phoneValid && nameValid;
  }
});
