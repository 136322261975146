import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["juros", "form"];

  initialize() {
    this.showJuros();
    setInterval(() => {
      this.toggleTargets();
    }, 5000);
  }

  showJuros() {
    this.jurosTarget.classList.remove("hide");
    this.formTarget.classList.add("hide");
    this.element.classList.remove("background2");
    this.element.classList.add("background1");
  }
  
  showForm() {
    this.jurosTarget.classList.add("hide");
    this.formTarget.classList.remove("hide");
    this.element.classList.remove("background1");
    this.element.classList.add("background2");
  }
  

  toggleTargets() {
    if (this.jurosTarget.classList.contains("hide")) {
      this.showJuros();
    } else {
      this.showForm();
    }
  }

  get jurosTarget() {
    return this.element.querySelector(".header-text-juros");
  }

  get formTarget() {
    return this.element.querySelector(".header-text-form");
  }
}

// document.addEventListener("DOMContentLoaded", function() {
//   const changediv = document.querySelector("[data-controller='changediv']");
//   if (changediv) {
//     const controller = new ChangedivController(changediv);
//   }
// });

