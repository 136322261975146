jQuery(window).on('load', function() {
    /**
     * When a call-to-action is clicked, we capture the name that is defined.
     * With the name, we scroll into view of an object which has an Id the same
     * as the name defined into call-to-action.
     * This is used exactly to avoid calling an ajax request into the system and
     * overload the server with much views functions.
     * 
     * TODO: implement this as a pattern listener -> function(id)
     * 
     */
    $('.click-call-to-action').on('click', function(){
      let id_to_call = "scroll";
      $("#" + id_to_call)[0].scrollIntoView({ behavior: 'smooth' });
    })

    /**
     * This code below, gets all the objects that has the accordion class defined in it. 
     * For the open and close status, we defined active or not.
     * For every click event on the accordion element, the if quote will test if it has any height
     * If has: set to null; else: set to the max height (somehow defined on the css)
     * 
     * TODO: transcompile this raw js into jQuery or choose to make a helper, so we can 
     * control better and reuse this code on future projects.
     */
    $(()=> {
        var acc = document.getElementsByClassName("accordion");
        var i;

        for (i = 0; i < acc.length; i++) {
            acc[i].addEventListener("click", function () {
                this.classList.toggle("active");
                var panel = this.nextElementSibling;
                if (panel.style.maxHeight) {
                    panel.style.maxHeight = null;
                } else {
                    panel.style.maxHeight = panel.scrollHeight + "px";
                }
            })
        }    
    })

    //Mostrar ou escoder o placeholder e o label dos inputs
    $(document).ready(function(){
        $('#formulario').find('input').on('focus', function(){
            if($(this).val() == ''){
                $(this).parent().find('label').show();
                $(this).data('placeholder', $(this).attr('placeholder'))
                $(this).attr('placeholder', '');
            } else if($(this).val() == ''){
                $(this).parent().find('label').hide();
            }
        });
    }
    )

    $(document).ready(function(){
        $('#formulario').find('input').on('focusout', function(){
            if($(this).val() == ''){
                $(this).parent().find('label').hide();
                $(this).attr('placeholder', $(this).data('placeholder'));
            }
        });
    }
    )
    //end
       
    //Pular para o proximo input quando aperta enter
    $(document).ready(function(){
        $('#formulario').find('input').on('keydown', function(e){
            if(e.keyCode == 13){
                e.preventDefault();
                $(this).parent().next().find('input').focus();
            }
        });
    });
})